<template>
    <comp-table ref="table" title="商家机构黑名单管理" :columns="columns" table-api="/gateway/api/sycompany/pc/blacklist/listSupplierBlacklist">
        <template v-slot:search="data">
            <Input v-model.trim="data.search.supplierName" placeholder="商家机构名称" style="width: 160px"></Input>
            <Input v-model.trim="data.search.orgCode" placeholder="机构编码" style="width: 160px"></Input>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../components/comp-table.vue"
import Request from "../utils/request"

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "商家机构名称",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "入驻时间",
                    minWidth: 250,
                    key: "settleTime",
                    align: "center",
                },
                {
                    title: "加入黑名单原因",
                    key: "remark",
                    minWidth: 250,
                    align: "center",
                },
                {
                    title: "黑名单时长（天）",
                    key: "settleTime",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "操作人",
                    key: "staffName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "操作时间",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.lockTime ? this.$core.formatDate(new Date(params.row.lockTime), "yyyy-MM-dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 150,
                    render: (h, params) => {
                        return [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.post("/gateway/api/sycompany/pc/blacklist/removeBlacklist", {
                                                supplierId: params.row.id,
                                            }).then(() => {
                                                this.$Message.success("解除成功")
                                                // 刷新表格数据
                                                setTimeout(() => {
                                                    this.$refs.table.refresh()
                                                }, 2000)
                                            })
                                        },
                                    },
                                },
                                "解除"
                            ),
                        ]
                    },
                },
            ],
        }
    },

    methods: {},
}
</script>
<style lang="less"></style>
